import './App.css'
import AppBar from './components/appBar'
import TopBody from './components/topBody'
import VoiceCategory from './components/voiceCategory'
import Fotter from './components/fotter'

function App() {
  return (
    <div className="bg-wallpy">
      <header>
        <AppBar />
        <TopBody />
        <VoiceCategory />
        <Fotter />
      </header>
    </div>
  )
}

export default App
