import logo from '../assets/vediologo.png'

function appBar() {
  return (
    <div className="flex flex-col bg-cover h-[600px] bg-fixed">
      <div className=" flex flex-row justify-around mx-5 lg:mx-0">
        <img className=" h-28 mt-5" src={logo} alt="img" />
        <a
          href="#c2"
          className="font-cairo whitespace-nowrap font-bold text-black bg-blue-light text-xl rounded-xl my-12 px-5 py-1  h-10  hover:bg-super-blue hover:text-white duration-150 ease-in-out"
        >
          تواصل معنا
        </a>
      </div>
      <div className="flex flex-col items-center pt-12">
        <h1
          id="Te_SH"
          className="font-cairo lg:text-8xl md:text-7xl sm:text-5xl text-4xl text-super-blue font-extrabold text-center whitespace-nowrap"
        >
          Echo Elegance
        </h1>
        <p className="font-cairo md:text-3xl lg:text-4xl text-md text-white font-light  my-16 whitespace-nowrap">
          استمتع بخدمات التعليق الصوتي الاحترافية على موقعنا
        </p>
        <a
          href="#c1"
          className="font-cairo whitespace-nowrap font-medium text-white bg-super-blue text-3xl rounded-xl mt-5 px-12 py-3  hover:px-16 hover:py-4 duration-150 ease-in-out"
        >
          ابدأ الآن
        </a>
      </div>
    </div>
  )
}

export default appBar
