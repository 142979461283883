import React, { Component } from 'react'

import mic from '../assets/mic.png'

class voiceCategory extends Component {
  render() {
    const samples = [
      {
        id: 1,
        mic_icon: 'robot',
        text: 'عينة تعليق صوتي',
        sample_type: 'رد آلي',
        link: 'https://www.youtube.com/watch?v=cQy7l3bWwr8',
      },
      {
        id: 2,
        mic_icon: 'social_media',
        text: 'عينة تعليق صوتي',
        sample_type: 'اعلان',
        link: 'https://www.youtube.com/watch?v=UyJp7tfynII',
      },
      {
        id: 3,
        mic_icon: 'write',
        text: 'عينة تعليق صوتي',
        sample_type: 'شعر',
        link: 'https://www.youtube.com/shorts/NxSrjSuOEn8',
      },
      {
        id: 4,
        mic_icon: 'pen',
        text: 'عينة تعليق صوتي',
        sample_type: 'قصة',
        link: 'https://www.youtube.com/watch?v=Z7cAPmqDUjQ',
      },
      {
        id: 5,
        mic_icon: 'earth',
        text: 'عينة تعليق صوتي',
        sample_type: 'ثقافية',
        link: 'https://www.youtube.com/watch?v=76QKtpMUbX0',
      },
    ]

    return (
      <div className="">
        <div className="flex flex-row justify-center mt-52 ">
          <h1
            id="c1"
            className="font-cairo sm:text-5xl text-3xl font-bold mx-5  text-white"
          >
            عينات التعليق الصوتي
          </h1>
          <img className="sm:w-16 sm:h-16 w-10 h-10" src={mic} alt="img" />
        </div>

        <div className="flex  justify-center mt-16 ">
          <div
            className="grid 2xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-14 "
            dir="rtl"
          >
            {samples.map((samples, index) => (
              <a
                className="w-[22rem] h-[19rem] sm:w-[30rem] sm:h-[20rem] hover:opacity-70 hover:rounded-[50px] duration-200 rounded-3xl bg-gradient-to-tr from-to to-from mx-auto shadow-black/25 shadow-[26px_26px_0px_0px] cursor-pointer"
                href={samples.link}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-20 ml-auto my-12 mx-16"
                  src={require('../assets/' + samples.mic_icon + '.png')}
                  alt="img"
                />
                <p className="font-cairo text-right text-white lg:text-4xl text-3xl font-bold px-14">
                  {samples.text}
                </p>
                <p className="font-cairo text-right text-white lg:text-4xl text-3xl font-bold px-14">
                  {samples.sample_type}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default voiceCategory
