import React, { Component } from 'react'
import logo from '../assets/vediologo.png'

class fotter extends Component {
  render() {
    const Content = [
      {
        id: 1,
        text: 'واتساب',
        img: 'whatsapp',
        link: 'https://wa.me/966551227021',
      },
      {
        id: 2,
        text: 'انستقرام',
        img: 'instagram',
        link: 'https://www.instagram.com/echo.elegance/',
      },
      {
        id: 3,
        text: 'ايميل',
        img: 'email',
        link: 'mailto:echoelegance23@gmail.com',
      },
    ]

    return (
      <center>
        <div
          id="c2"
          className="bg-blue-dark text-white py-24 mt-28 px-5 flex sm:justify-evenly justify-center items-center w-full"
        >
          <div className=" ">
            <img className="h-52 sm:block hidden " src={logo} alt="img" />
          </div>
          <div>
            <ul>
              <div className="">
                <li className="font-cairo lg:text-4xl text-3xl pb-5 font-extrabold ">
                  تواصل معنا
                </li>
              </div>
              <div className="grid grid-row-3">
                {Content.map((Content, index) => (
                  <div className="flex py-5 my-2 w-72 h-24  hover:opacity-70 hover:rounded-3xl duration-200 rounded-xl bg-gradient-to-tr from-to to-from mx-auto  cursor-pointer">
                    <a
                      href={Content.link}
                      target="_blank"
                      className="font-cairo cursor-pointer font-bold text-3xl text-right ml-auto my-auto"
                      rel="noreferrer"
                    >
                      {Content.text}
                    </a>
                    <p></p>
                    <img
                      id="ss"
                      src={require('../assets/' + Content.img + '.png')}
                      key={index}
                      alt="img"
                      className="w-10 h-10 mx-4 my-auto"
                    />
                  </div>
                ))}
              </div>
            </ul>
          </div>
        </div>
      </center>
    )
  }
}
export default fotter
