import quality from '../assets/quality.png'
import lightbulb from '../assets/lightbulb.png'

function topBody() {
  return (
    <div className="flex xl:flex-row flex-col justify-center bg-blue-light mx-auto my-20 rounded-[40px] p-20 max-w-6xl">
      <div className="">
        <img className="w-16 ml-auto mb-5" src={lightbulb} alt="" />
        <p className="font-cairo lg:text-4xl text-3xl text-right">
          ازرع فكرتك واطلب التعليق الصوتي المثالي من خلال موقعنا
        </p>
      </div>
      <div className=" w-1 mx-16 bg-black"></div>
      <div className=" h-0.5 my-10   bg-black"></div>
      <div className="">
        <img className="w-16 ml-auto mb-5" src={quality} alt=""></img>
        <p className="font-cairo lg:text-4xl text-3xl text-right">
          نقدم التعليق بأعلى جودة وبسعر منافس
        </p>
      </div>
    </div>
  )
}

export default topBody
